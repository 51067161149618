<template>
    <div class="main-body">
		<p class="center" v-if="mode=='verifyEmail'">{{messageText}}</p>

		<div class="auth-body-wrapper" v-if="mode=='resetPassword'">
            <div class="auth-body center">
                <p class="auth-title">Reset your Password</p>
                <p class="auth-description">Enter your new pasword</p>

                <div class="auth-input-container">
                    <div class="auth-input-box">
                        <p class="input-label">Password</p>
                        <input class="input-box" type="password" name="password" v-model="password" placeholder="Enter Password">
                    </div>
                    <div class="auth-input-box">
                        <p class="input-label">Confirm Password</p>
                        <input class="input-box" type="password" name="password" v-model="confirmPassword" placeholder="Repeat Password">
                    </div>
                    <div class="btn sign-up-btn" @click="resetPassword()">
                        <p v-if="isSubmitting" style="height: 27px;position: relative;"><svg class="center" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="/* margin-top: 20px; */background: none;shape-rendering: auto;position: relative;width: 100%;text-align: center;height: 29px;/* top: 4px; */"><circle cx="50" cy="50" fill="none" stroke="#fff" stroke-width="8" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(274.643 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg></p>
                        <p v-if="!isSubmitting" class="sign-up-text">Reset Password</p>
                    </div>
                    
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { createUserInfo } from '@/apis/authentication'
import { checkActionCode } from "@/apis/authentication";
import { applyActionCode } from "@/apis/authentication";
import { confirmPasswordReset } from "@/apis/authentication";
import { verifyPasswordResetCode } from "@/apis/authentication";

export default {
    name: 'AuthAction',
	data(){
		return{
			code: undefined,
			mode: undefined,
			uid: undefined,
			messageText: 'Validating..',
			password: '',
			confirmPassword: '',
			resetCodeStatus: false,
			isSubmitting: false
		}
	},
    mounted(){
		this.getParams();
    },
    methods:{
		getParams(){
			var url = new URL(window.location.href);
			this.code = url.searchParams.get("oobCode");
			this.mode = url.searchParams.get("mode");

			if(this.mode == "verifyEmail"){
				var uid_url = new URL(url.searchParams.get("continueUrl"));
				this.uid = uid_url.searchParams.get("uid");
				this.checkActionCode();
			}
			else if(this.mode == "resetPassword"){
				this.verifyPasswordResetCode();
			}
		},
        // Check if action code is valid
        async checkActionCode(){
            var status = await checkActionCode(this.code);
			if(status['data']!=undefined){
				this.email = status['data']['email'];
				var applyStatus = await applyActionCode(this.code);
				if(applyStatus){
					this.updateUserInfoFirestore();
				}
			}
			else{
				this.messageText = 'Failed. Redirecting..';
				var errorCode = status["code"];
				if(errorCode == "auth/invalid-action-code"){
                    this.$root.$refs['snackbar'].showToast("Invalid Code/ Code Expired", "#e52627");
                }
                else{
                    this.$root.$refs['snackbar'].showToast("Something went wrong", "#e52627");
                }
				setTimeout(function(){
					window.location.href = "/";
				}, 3000);
			}
		},
		async updateUserInfoFirestore(){
			var data = {
                "userId": this.uid,
                "email": this.email,
                "isVerified": true,
                "isSubscribed": true
            }
            var status = await createUserInfo(this.uid, data);
			if(status){
				this.messageText = "Email Verified."
				this.$root.$refs['snackbar'].showToast("Email Verified. Redirecting..", "#33a854");
				setTimeout(function(){
					window.location.href = "/";
				}, 3000);
			}
		},
		async verifyPasswordResetCode(){
			this.resetCodeStatus = await verifyPasswordResetCode(this.code);
		},
		async resetPassword(){
			if(this.password==undefined||this.password.length<6){
				this.$root.$refs['snackbar'].showToast("Password should be atleast 6 characters", "#e52627");
			}
			else if(this.confirmPassword==undefined||(this.password!=this.confirmPassword)){
				this.$root.$refs['snackbar'].showToast("Passwords do not match", "#e52627");
			}
			else{
				this.isSubmitting = true;

				var passwordStatus = await confirmPasswordReset(this.code, this.password);
				if(passwordStatus){
					this.$root.$refs['snackbar'].showToast("Password Updated Successfully. Redirecting to Login Page..", "#33a854");
					this.isSubmitting = false;
                    setTimeout(function(){
                        this.isSubmitting = false;
                        window.location.href = "/sign-in";
                    }, 3000);
				}
				else{
					this.$root.$refs['snackbar'].showToast("Something went wrong", "#e52627");
					this.isSubmitting = false;
				}
			}
		}
    }
}
</script>

<style scoped>
	.main-body{max-width: 1280px;margin: auto;margin-bottom: 48px;min-height: 50vh;}
	.auth-body-wrapper{position: relative;padding: 0 12px;width: 100%;min-height: calc(100vh - 105px);}
	.auth-body{width: 100%;max-width: 350px;}
    .auth-title{font-size: 20px;font-weight: 700;padding: 4px 0;display: inline-block;line-height: 24px;margin-bottom: 8px;}
    .auth-description{font-size: 15px;line-height: 1.4;font-weight: 400;color: #868686;}

	.auth-input-container{margin: 16px 0px;}
    .auth-input-box{margin-bottom: 6px;}
    .auth-input-box input-box{padding: 9px 12px;}
    .input-label{padding: 8px 4px;padding-bottom: 4px;font-size: 14px;font-weight: 500;}
    .sign-up-btn{margin: 0px;min-width: 120px;width: 100%;margin-top: 12px;padding: 7px 16px;line-height: 27px;}
    .sign-up-text{font-size: 15px;}

	/* Tablet */
    @media only screen and (max-width: 1024px){
        .main-body{grid-template-columns: 1fr;margin: 0;}
        .auth-body-wrapper{margin-left: unset;max-width: unset;height: 400px;min-height: 60vh;}
        .auth-body{margin: 0;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
    }

    /* Phone */
    @media only screen and (max-width: 600px){
        .auth-body{width: 90%;}
    }
</style>